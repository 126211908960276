// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-autoclue-js": () => import("./../../../src/pages/autoclue.js" /* webpackChunkName: "component---src-pages-autoclue-js" */),
  "component---src-pages-channels-js": () => import("./../../../src/pages/channels.js" /* webpackChunkName: "component---src-pages-channels-js" */),
  "component---src-pages-chemical-raw-materials-js": () => import("./../../../src/pages/chemical-raw-materials.js" /* webpackChunkName: "component---src-pages-chemical-raw-materials-js" */),
  "component---src-pages-childrenprivacy-js": () => import("./../../../src/pages/childrenprivacy.js" /* webpackChunkName: "component---src-pages-childrenprivacy-js" */),
  "component---src-pages-collaboration-js": () => import("./../../../src/pages/collaboration.js" /* webpackChunkName: "component---src-pages-collaboration-js" */),
  "component---src-pages-cookieprivacy-js": () => import("./../../../src/pages/cookieprivacy.js" /* webpackChunkName: "component---src-pages-cookieprivacy-js" */),
  "component---src-pages-enterprise-service-js": () => import("./../../../src/pages/enterprise-service.js" /* webpackChunkName: "component---src-pages-enterprise-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-post-layout-jsx": () => import("./../../../src/templates/PostLayout.jsx" /* webpackChunkName: "component---src-templates-post-layout-jsx" */)
}

